import React from 'react';
import { Link } from "react-router-dom";
import "./GameSelect.css";

import {
  allGameData,
} from '../gameData';

import {
  clearStoredGame,
} from '../dataStore';

let resetCouter = 0;

function GameSelect() {

  function handleClearButtonClick (gameId) {
    if (window.confirm("Would you like to clear the game progress?")) {
      clearStoredGame(gameId);
    }
  }

  function handleTitleTap (e) {
    resetCouter++;
    if (resetCouter > 10) {
      resetCouter = 1;
      handleClearButtonClick('pmo2025');
    }
  }
  if (typeof window !== 'undefined' && resetCouter === 0) {
    resetCouter++;

    window.addEventListener('keydown', e => {
      if (e.code === 'Backquote') {
        resetCouter++;
      }
      if (resetCouter > 6) {
        resetCouter = 1;
        handleClearButtonClick('pmo2025');
      }
    }, false)
  }

  return (
    <div className="GameSelect">
      {allGameData.map(game => (
        <div key={game.id}>
          <h1 className="gameTitle" onTouchStart={handleTitleTap}>{game.title}</h1>
          <div className="buttonWrapper">
            <Link to={`/${game.id}`}>
              Start
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GameSelect;
